var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:(_vm.customer.relationship
      ? 'background-image: linear-gradient(#b99baf, #73385f);'
      : 'background-image: linear-gradient(#9bafb9, #385F73);'),attrs:{"dark":""}},[_c('div',{staticClass:"d-flex flex-nowrap"},[(_vm.customer.picture_link)?_c('v-avatar',{staticClass:"ma-3 mr-0",attrs:{"size":"100","tile":""}},[_c('v-img',{attrs:{"src":_vm.customer.picture_link}})],1):_vm._e(),_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',{staticClass:"headline flex-nowrap"},[_vm._v(" "+_vm._s(_vm.customer.fullname)+" "),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-start",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{
                name: 'MyNotes',
                params: { cid: _vm.customer.id },
              }}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-notebook")]),_vm._v(" Notes")],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":{
                name: 'MyEvent',
                params: { cid: _vm.customer.id },
              }}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-check")]),_vm._v(" Join Event")],1)],1),_c('v-list-item',{attrs:{"to":{
                name: 'MyAppointment',
                params: { cid: _vm.customer.id },
              }}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-plus")]),_vm._v(" Book Appointment")],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":{
                name: 'PayOnline',
                params: { cid: _vm.customer.id },
              }}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cash-register")]),_vm._v(" Renew Subscription")],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.showUploadPicture = true}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-image")]),_vm._v(" Update Photo")],1)],1)],1)],1)],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.customer.cust_no)+" "),(_vm.customer.relationship)?_c('span',[_vm._v("( "+_vm._s(_vm.customer.relationship)+" )")]):_vm._e()]),_c('v-card-subtitle',{staticClass:"mb-0",staticStyle:{"margin-top":"-20px"}},[(_vm.activePasses)?_c('v-badge',{staticClass:"float-right",attrs:{"content":_vm.activePasses,"color":"success","overlap":"","left":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"pink"}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-ticket-confirmation")])],1)]}}],null,false,217899737)},[_c('span',[_vm._v("Active Passes")])])],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showQRCode = true}}},[_c('v-icon',[_vm._v("mdi-qrcode")]),_vm._v(" QR Code")],1),_c('v-spacer'),_c('v-badge',{attrs:{"content":_vm.expiredSubscriptions,"color":_vm.expiredSubscriptions ? 'error' : '',"overlap":"","left":""}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.expandSub = !_vm.expandSub}}},[_vm._v(" Subscriptions "),(_vm.expandSub)?_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-up")]):_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)],1)],1),_c('v-divider'),_c('v-expand-transition',[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandSub),expression:"expandSub"}],attrs:{"dense":"","light":""}},[_vm._l((_vm.services),function(service,service_index){return [_c('v-list-item',{key:service.service_customer_id},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{attrs:{"to":{
                    name: 'MySubscription',
                    params: {
                      cid: _vm.customer.id,
                      scid: service.service_customer_id,
                    },
                  },"exact":""}},[_vm._v(" "+_vm._s(service.service_name)+" ")]),(service.passes_counter != null)?_c('div',{staticClass:"pt-2"},[_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-ticket-confirmation")]),_vm._v(" Passes "),_c('v-avatar',{staticClass:"darken-4",class:service.passes_counter == 0
                          ? 'red'
                          : service.passes_counter < 3
                          ? 'yellow'
                          : 'green',attrs:{"right":""}},[_vm._v(" "+_vm._s(service.passes_counter)+" ")])],1)],1):_vm._e()],1),_c('v-spacer'),_c('div',{staticClass:"d-flex flex-column align-end"},[_c('span',{staticClass:"text-caption text-no-wrap"},[_vm._v(_vm._s(_vm.toDate(service.service_dateto)))]),_c('div',[(!service.service_dateto)?_c('v-chip',{attrs:{"small":"","color":"orange","text-color":"white"}},[_vm._v("No Record")]):(_vm.isExpired(service.service_dateto))?_c('v-chip',{attrs:{"small":"","color":"red","text-color":"white"}},[_vm._v("Expired")]):_vm._e()],1),(_vm.isExpired(service.service_dateto))?_c('v-btn',{staticClass:"mt-1",attrs:{"small":"","color":"primary","to":{
                  name: 'PayOnline',
                  params: { cid: _vm.customer.id }
                }}},[_vm._v("Renew Now")]):_vm._e()],1)],1)])],1),_c('v-divider',{key:service_index})]})],2)],1),(_vm.showQRCode)?_c('qrcode',{ref:"qrcode",attrs:{"value":'https://fee.plus/b/' + _vm.businessId + '/c/' + _vm.customer.id + '/qr'},on:{"close":function($event){_vm.showQRCode = false}}}):_vm._e(),(_vm.showUploadPicture)?_c('upload-picture',{ref:"uploadPicture",attrs:{"remove-photo":_vm.customer.picture_link ? true : false,"dialogTitle":"Update Picture"},on:{"save":_vm.savePicture,"delete":_vm.deletePicture,"close":function($event){_vm.showUploadPicture = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
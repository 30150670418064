<template>
  <v-card>
    <v-toolbar color="#952175" dark>
      <v-toolbar-title
        ><v-icon>mdi-calendar-multiselect</v-icon> My Schedules</v-toolbar-title
      >
      <!-- <v-spacer></v-spacer>

      <v-btn icon @click="showAll = !showAll">
        <v-icon v-if="!showAll">mdi-expand-all</v-icon>
        <v-icon v-else>mdi-collapse-all</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-list>
      <!-- <v-list-item-group active-class="pink--text"> // this tag so that the list is clickable -->
      <template v-for="(event, index) in events">
        <v-subheader
          v-if="event.header"
          :key="event.header"
          v-text="event.header"
          style="background-color:#ebeff1;"
        ></v-subheader>
        <v-list-item v-else :key="event.event_id+'.'+event.parent_event_instance_id" :class="event.is_cancelled?'cancelled_event':''">
          <template>
            <v-list-item-content>
              <v-list-item-title class="d-flex flex-nowrap">
                <span>{{ event.event_title }}</span>
                <v-spacer></v-spacer>
                <div v-text="getTime(event)"></div>
              </v-list-item-title>
              <v-list-item-subtitle class="text--primary d-flex">
                {{ event.locname }}
                <v-chip v-if="event.is_change_location" small color="info" dark class="ml-1">
                  <v-icon small left>mdi-map-marker</v-icon>
                  Changed
                </v-chip>
                <v-spacer></v-spacer>
                <div>
                <v-chip v-if="event.is_cancelled" small color="red" dark>
                  Cancelled
                  <v-icon small right>mdi-calendar-remove</v-icon>
                </v-chip>
                <v-chip v-if="event.is_rescheduled" small color="warning" dark class="ml-1">
                  Rescheduled
                  <v-icon small right>mdi-calendar-clock</v-icon>
                </v-chip>
                <v-chip v-if="event.parent_event_instance_id" small color="indigo" dark class="ml-1">
                  Rescheduled
                  <v-icon small right>mdi-calendar-check</v-icon>
                </v-chip>
                <!-- <v-chip v-if="event.event_chip" small :color="event.event_chip.color" dark>
                  {{ event.event_chip.label }}
                  <v-icon small right>{{ event.event_chip.icon }}</v-icon>
                </v-chip> -->
                <v-chip v-if="event.chip && !event.is_cancelled" small :color="event.chip.color" dark class="ml-1">
                  {{ event.chip.label }}
                  <v-icon small right>{{ event.chip.icon }}</v-icon>
                </v-chip>
                <v-menu
                  v-if="(event.participant_type !== null || event.participation_status == 2 || event.participation_status == 3) &&  allowLeave(event) && !event.suggestion && !event.is_cancelled"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>{{
                        customer.fullname
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="takeLeave(customer.id, event)">
                      <v-list-item-title><v-icon color="red">mdi-calendar-remove</v-icon> Take Leave</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  v-else-if="event.suggestion && event.show_join && !event.is_cancelled"
                  small
                  outlined
                  color="primary"
                  :to="{
                    name: 'MyEvent',
                    params: {
                      cid: customer.id,
                      event_id: event.event_id,
                      event_date: event.event_date,
                    },
                  }"
                  >Join <v-icon right>mdi-calendar-check-outline</v-icon></v-btn
                >
                </div>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-for="(dependant, d_index) in event.dependants"
                :key="d_index"
              >
                <v-icon>mdi-account</v-icon> {{ dependant.fullname }}
                <span v-if="dependant.gin">({{ dependant.gin }}) </span>
                <v-chip
                  v-if="dependant.chip"
                  small
                  :color="dependant.chip.color"
                  dark
                >
                  {{ dependant.chip.label }}
                  <v-icon small right>{{ dependant.chip.icon }}</v-icon>
                </v-chip>
                <v-menu
                  v-else-if="dependant.participant_type !== null && allowLeave(event) && !dependant.suggestion && !event.is_cancelled"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>{{
                        dependant.fullname
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="takeLeave(dependant.id, event)">
                      <v-list-item-title
                        ><v-icon color="red">mdi-calendar-remove</v-icon> Take
                        Leave</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  v-else-if="dependant.suggestion && event.show_join && !event.is_cancelled"
                  small
                  outlined
                  color="primary"
                  :to="{
                    name: 'MyEvent',
                    params: {
                      cid: dependant.id,
                      event_id: event.event_id,
                      event_date: event.event_date,
                    },
                  }"
                  >Join <v-icon right>mdi-calendar-check-outline</v-icon></v-btn
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </v-list-item-action> -->
            <!-- <v-list-item-action>
              <v-list-item-action-text
                class="font-weight-bold"
                v-text="getTime(event)"
              ></v-list-item-action-text>
              <v-chip v-if="event.chip" small :color="event.chip.color" dark>
                  {{ event.chip.label }}
                  <v-icon small right>{{ event.chip.icon }}</v-icon>
                </v-chip>
              <v-btn v-else-if="event.suggestion" small outlined color="primary">Join <v-icon right>mdi-calendar-check-outline</v-icon></v-btn>
            </v-list-item-action> -->
          </template>
        </v-list-item>
        <!-- <v-divider
          v-if="!event.header && index + 1 < events.length"
          :key="index"
        ></v-divider> -->
        <v-divider :key="index"></v-divider>
      </template>
      <v-banner v-if="!showAll && !events.length">
        <v-icon slot="icon" color="warning" size="36">
          mdi-calendar-alert
        </v-icon>
        No schedule for today.
      </v-banner>
      <v-subheader>
        <v-spacer></v-spacer>
        <v-btn
          v-if="showAll"
          text
          small
          outlined
          color="red"
          @click="showAll = false"
          ><v-icon dense small>mdi-collapse-all-outline</v-icon> Hide weekly
          schedules</v-btn
        >
        <v-btn v-else text small outlined color="indigo" @click="showAll = true"
          ><v-icon dense small>mdi-expand-all-outline</v-icon> Show weekly
          schedules</v-btn
        >
      </v-subheader>
      <!-- </v-list-item-group> -->
      <!-- <v-list-item-group>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title class="float-right">Show All</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group> -->
    </v-list>
  </v-card>
</template>
<script>
import moment from "moment";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      showAll: false,
    };
  },
  mounted() {
    if (!this.events.length) {
      try {
        this.$store.dispatch("fetchEvents");
      } catch (error) {
        console.log("error", error);
      }
    }
  },
  methods: {
    showEvent(event) {
      if (this.showAll) {
        if (
          moment(event.start_time).isBefore(
            moment()
              .clone()
              .startOf("day"),
            "d"
          )
        )
          return false;
        else return true;
      } else if (this.isToday(event.start_time)) return true;
      else return false;
    },
    isToday(date) {
      return moment(date).isSame(
        moment()
          .clone()
          .startOf("day"),
        "d"
      );
    },
    getTime(event) {
      let st = moment(event.start_time),
        et = moment(event.end_time),
        //date = this.isToday(st) ? "Today" : st.format("ddd, MMM D"),
        same = st.format("A") == et.format("A");
      return (
        moment(event.start_time).format("h:mm" + (same ? "" : " A")) +
        "-" +
        moment(event.end_time).format("h:mm A")
      );
    },
    getDependantDetails(id) {
      if (this.customer.dependants)
        return this.customer.dependants.find((customer) => customer.id == id);
    },
    getParticipationData(status) {
      switch (status) {
        case -2:
          return {
            color: "red",
            icon: "mdi-close-circle-outline",
            label: "Request Rejected"
          };
        case -1:
          return {
            color: "red",
            icon: "mdi-close-circle-outline",
            label: "Took Leave"
          };
        case 0:
          return {
            color: "red",
            icon: "mdi-close-circle-outline",
            label: "Absent",
          };
        case 1:
          return {
            color: "green",
            icon: "mdi-check-circle-outline",
            label: "Attend",
          };
        case 3:
          return {
            color: "success",
            icon: "mdi-checkbox-marked-circle-outline",
            label: "Approved",
          };
        case 8: // Requested
          return {
            color: "primary",
            icon: "mdi-clock-check-outline",
            label: "Awaiting Approval",
          };
        case null:
          return null;
      }
    },
    allowLeave(event){
      // if(event.allow_leave){
      //   let daydiff = moment(moment(event.start_time).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'),'days')
      //   // console.log(event, daydiff)
      //   return event.leave_days_allowed <= daydiff
      // }
      // 20220210 - I had remove the event.leave_days_allowed and replaced with leave_cutoff_hours,
      // to still show leave button althought missed the cut-off time but will tell the user about it.
      return event.allow_leave
    },
    takeLeave(cid, event) {
      let leaveCutOffTime = moment(event.start_time).subtract({hours: event.leave_cutoff_hours})
      if(leaveCutOffTime.isAfter()){
        Swal.fire({
          icon: "question",
          title: "Are you sure you want to take leave?",
          text: "Any message for the business e.g. reason for taking leave:",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#F44336",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          input: "text",
        }).then((result) => {
          //console.log(result.value)
          if (result.isConfirmed) {
            this.$store.dispatch({
              type: "eventTakeLeave",
              dcid: this.$store.getters.isDependant(cid) ? cid : null,
              event_id: event.event_id,
              event_date: event.event_date,
              remark: result.value
            })
            .then(res => {
              if(res.error == true){
                Swal.fire({
                  icon: "error",
                  text: res.message
                })
              }
            })
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "You can't take leave now!",
          // text: `You have already pass the leave cut off time of ${leaveCutOffTime.format("LLLL")} (${event.leave_cutoff_hours} hour(s) before the event starts)`
          html: `You have missed the cut-off time of <b>${event.leave_cutoff_hours} hour(s)</b> before the event starts.`
        })
      }
    },
  },
  computed: {
    customer() {
      return this.$store.state.customer;
    },
    events() {
      // return events with sorted start_time
      // TODO: join click event
      let events = [],
        dateTemp = "",
        data = this.$store.state.events;
      // TODO: use watch and eventsList variable instead
      if (Array.isArray(data)) {
        data
          .filter((event) => this.showEvent(event))
          .sort(function(a, b) {
            return moment(a.start_time).diff(moment(b.start_time));
          })
          .forEach((a) => {
            let theDate = moment(a.start_time).format("YYYY-MM-DD");
            if (theDate !== dateTemp) {
              events.push({
                header: this.isToday(theDate)
                  ? "Today"
                  : moment(theDate, "YYYY-MM-DD").format("dddd, MMMM D, YYYY"),
              });
              dateTemp = theDate;
            }
            // check event status
            a.event_chip = '';
            if(a.is_cancelled) a.event_chip = {
              color: "red",
              icon: "mdi-calendar-remove",
              label: "Cancelled"
            }
            if(a.is_rescheduled) a.event_chip = {
              color: "warning",
              icon: "mdi-calendar-clock",
              label: "Rescheduled"
            }
            // check participation
            a.chip = this.getParticipationData(a.participation_status);
            // check dependant
            if (a.dependants)
              a.dependants.map((dependant) => {
                let data = this.getDependantDetails(dependant.id);
                if (data) {
                  dependant.fullname = data.fullname;
                  dependant.chip = this.getParticipationData(
                    dependant.participation_status
                  );
                }
                return dependant;
              });
            events.push(a);
          });
      }
      return events;
    },
  },
};
</script>
<style scoped>
.v-list-item__title > span {
  white-space: pre-wrap;
}
.v-list-item.cancelled_event {
  background-color:#FFCDD2;
}
</style>

<template>
  <v-card v-if="showNotices">
    <v-toolbar color="orange" dark>
      <v-toolbar-title
        ><v-icon>mdi-alert</v-icon> Notice</v-toolbar-title
      >
      <v-spacer></v-spacer>

      <v-btn icon @click="refreshNotice()" title="Refresh Notice">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list>
      <!-- <v-subheader
          style="background-color:#ebeff1;"
        >Outstanding Invoices</v-subheader> -->
      
      <template v-for="(invoice, index) in notices.invoices">
        <v-subheader
          v-if="invoice.headerTitle"
          :key="index"
          v-text="invoice.headerTitle"
          style="background-color:#ebeff1;"
        ></v-subheader>

        <v-list-item :key="'i'+invoice.invoice_id">
          <template>
            <v-list-item-content>
              <v-list-item-title class="d-flex flex-nowrap">
                <a href="#" @click.prevent="showPdf(invoice.invoice_id)" title="View invoice">{{ invoice.invoice_no }} - {{ invoice.invoice_date }}</a>
                <v-spacer></v-spacer>
                <div>{{ invoice.currency_short + toCurrency(invoice.outstanding_amount) }}</div>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex">
                <div class="text-wrap">{{ invoice.description }}</div>
                <v-spacer></v-spacer>
                <v-btn small color="primary" :to="{
                  name: 'PayOnline',
                  params: {
                    cid: invoice.customer_id,
                    iid: invoice.invoice_id
                  }
                }">Pay Now</v-btn> <!-- @click="payNow(invoice.customer_id, invoice.invoice_id)" -->
              </v-list-item-subtitle> 
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </v-list>
    
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      showNotices: false
    }
  },
  async mounted() {
    if (!this.notices.length) {
      this.refreshNotice();
    }
  },
  methods: {
    toCurrency(str) {
      let num = parseFloat(str);
      num = isNaN(num) ? 0.0 : num;
      return num.toFixed(2);
    },
    async refreshNotice() {
      try {
        await this.$store.dispatch("fetchNotices");
      } catch (error) {
        console.log("error", error);
      }
    },
    showPdf(invoice_id) {
      this.$store.dispatch({
        type: "fetchInvoicePdf",
        iid: invoice_id,
        // dcid: this.dcid
      }).then(d => this.$router.push({
        name: 'ViewPdf',
        params: {
          src: d.pdf_url
        }
      }));
    },
    payNow(customer_id, invoice_id) {
      this.$router.push({
        name: 'PayOnline',
        params: {
          cid: customer_id,
          iid: invoice_id
        }
      })
      // this.$store.dispatch({
      //   type: "fetchInvoiceForPayment",
      //   iid: invoice_id,
      //   // dcid: this.dcid
      // }).then(d => console.log(d));
    }
  },
  computed: {
    notices() {
      return this.$store.state.notices;
    },
    customer() {
      return this.$store.state.customer;
    }
  },
  watch: {
    notices(value){
      if('invoices' in value && value.invoices.length){
        let cid = null
        value.invoices.map(i => {
          if(i.customer_id !== cid){
            cid = i.customer_id
            if(cid == this.customer.id){
              i.headerTitle = 'Outstanding Invoices'
            } else {
              this.customer.dependants.forEach((dependant) => {
                if (cid == dependant.id) {
                  i.headerTitle = `Outstanding Invoices (${dependant.fullname})`
                }
              });
            }
          }
          return i
        })
        this.showNotices = true;
      } else {
        this.showNotices = false;
      }
    }
  }
};
</script>
<style scoped>
.v-list-item__title > span {
  white-space: pre-wrap;
}
</style>

import moment from "moment";

export default {
  methods: {
    isExpired(d) {
      return moment(d).isBefore(
        moment()
          .clone()
          .startOf("day"),
        "d"
      );
    },
    isToday(d) {
      return moment(d).isSame(
        moment()
          .clone()
          .startOf("day"),
        "d"
      );
    },
    toDate(d) {
      return d ? moment(d).format("D-MMM-YYYY") : "";
    },
    toDayDate(d) {
      return moment(d).format("ddd, MMM D, YYYY");
    },
    toTimeRange(st, et) {
      st = moment(st);
      et = moment(et);
      let same = st.format("A") == et.format("A");
      return st.format("h:mm" + (same ? "" : " A")) + "-" + et.format("h:mm A");
    },
  }
}
<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="5" lg="4">
        <!-- style="background-image: linear-gradient(#9bafb9, #385F73);" -->
        <my-profile
          v-if="customer.is_customer"
          :key="customer.id"
          :customer="customer"
          :business-id="business.id" />
        <div v-if="customer.dependants" class="mt-2">
        <my-profile
          v-for="(dependants, i) in customer.dependants"
          :key="dependants.id"
          :customer="dependants"
          :business-id="business.id"
          :class="{ 'mb-2': i !== customer.dependants.length - 1 }" />
        </div>
        <!-- <v-card
          dark
          v-for="(customer, i) in business.customers"
          :key="i"
          :class="{ 'mb-2': i !== business.customers.length - 1 }"
          :style="
            customer.relationship
              ? 'background-image: linear-gradient(#b99baf, #73385f);'
              : 'background-image: linear-gradient(#9bafb9, #385F73);'
          "
        >
          <div class="d-flex flex-nowrap">
            <v-avatar class="ma-3 mr-0" size="100" tile>
              <v-img
                src="http://localhost/FeePlus/app/media/users/1/profile/pp_5cf6acf01e878.jpg"
              ></v-img>
            </v-avatar>
            <div class="flex-grow-1">
              <v-card-title class="headline">
                {{ customer.fullname }}
              </v-card-title>

              <v-card-subtitle>
                {{ customer.cust_no }}
                <span v-if="customer.relationship"
                  >( {{ customer.relationship }} )</span
                >
              </v-card-subtitle>

              <v-card-subtitle class="mb-0">
                <v-badge
                  content="2"
                  color="success"
                  overlap
                  class="float-right"
                >
                  <v-btn fab dark small color="pink" class="white--text"
                    ><v-icon dark>mdi-ticket-confirmation</v-icon></v-btn
                  >
                </v-badge>
              </v-card-subtitle>
            </div>
          </div>

          <v-card-actions>
            <v-btn text @click="showQRCode(customer.id)"
              ><v-icon>mdi-qrcode</v-icon> QR Code</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn text @click="expand[i] = !expand[i]"
              >Subscriptions <v-icon right>mdi-chevron-down</v-icon></v-btn
            >
          </v-card-actions>

          <v-divider></v-divider>
          <v-expand-transition>
            <v-list dense light v-show="expand[i]">
              <template v-for="(service, service_index) in customer.services">
                <v-list-item :key="service.service_customer_id">
                  <v-list-item-content
                    v-text="service.service_name"
                  ></v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                    v-text="service.service_dateto"
                  ></v-list-item-content>
                </v-list-item>
                <v-divider :key="service_index"></v-divider>
              </template>
            </v-list>
          </v-expand-transition>
          <v-expand-transition>
            <v-list dense light>
              <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Subscriptions</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(service, service_index) in customer.services">
                <v-list-item :key="service.service_customer_id">
                  <v-list-item-content
                    v-text="service.service_name"
                  ></v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                    v-text="service.service_dateto"
                  ></v-list-item-content>
                </v-list-item>
                <v-divider :key="service_index"></v-divider>
              </template>
              </v-list-group>
            </v-list>
          </v-expand-transition>
        </v-card> -->
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <my-notices v-if="loaded" class="mb-3"></my-notices>
        <my-schedules v-if="loaded"></my-schedules>
      </v-col>
    </v-row>
    <!-- <qrcode ref="qrcode"></qrcode> -->
  </div>
</template>
<script>
import MySchedules from "@/components/MySchedules.vue";
// import qrcode from "@/components/QRCode";
import MyProfile from "@/components/MyProfile.vue";
import MyNotices from "@/components/MyNotices.vue";

export default {
  name: "HomeMain",
  data() {
    return {
      loaded: false
      // qrcode: false,
      // myQRCode: "0",
      // subExpand: {},
      // expand: []
    };
  },
  async mounted() {
    if(!this.customer.id) {
      try {
        await this.$store.dispatch("fetchCustomer");
      } catch (error) {
        console.log('error', error)
      }
    }
    if(!Object.keys(this.$store.state.customer_services).length) {
      try {
        await this.$store.dispatch("fetchCustomerServices")
      } catch (error) {
        console.log('error', error)
      }
    }
    this.loaded = true;
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    business() {
      // let business = store.state.business;
      // business.customers.map(c => {
      //   if(!(c.id in this.subExpand)) this.subExpand[c.id] = false;
      //   c.expand = false;
      //   return c;
      // });
      // return business;
      return this.$store.state.business;
    },
    customer() {
      return this.$store.state.customer;
    }
  },
  methods: {
    // showQRCode(cid) {
    //   this.$refs.qrcode.show(
    //     "https://fee.plus/b/" + this.business.id + "/c/" + cid + "/qr"
    //   );
    // },
    // showSub(cid) {
    //   this.business.customers.map(c => {
    //     if(c.id == cid) c.expand = !c.expand;
    //     console.log(c);
    //     return c;
    //   });
      // this.subExpand[cid] = !this.subExpand[cid];
      // console.log(this.subExpand[cid]);
    // }
  },
  components: {
    //qrcode,
    MyProfile,
    MySchedules,
    MyNotices,
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

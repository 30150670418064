<template>
  <v-card
    dark
    :style="
      customer.relationship
        ? 'background-image: linear-gradient(#b99baf, #73385f);'
        : 'background-image: linear-gradient(#9bafb9, #385F73);'
    "
  >
    <div class="d-flex flex-nowrap">
      <v-avatar v-if="customer.picture_link" class="ma-3 mr-0" size="100" tile>
        <v-img :src="customer.picture_link"></v-img>
      </v-avatar>
      <div class="flex-grow-1">
        <v-card-title class="headline flex-nowrap">
          {{ customer.fullname }}
          <v-spacer></v-spacer>
          <!-- <v-btn icon class="align-self-start"
            ><v-icon>mdi-dots-vertical</v-icon></v-btn
          > -->
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                class="align-self-start"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{
                  name: 'MyNotes',
                  params: { cid: customer.id },
                }"
              >
                <v-list-item-title
                  ><v-icon left>mdi-notebook</v-icon> Notes</v-list-item-title
                >
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                :to="{
                  name: 'MyEvent',
                  params: { cid: customer.id },
                }"
              >
                <v-list-item-title
                  ><v-icon left>mdi-calendar-check</v-icon> Join
                  Event</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'MyAppointment',
                  params: { cid: customer.id },
                }"
              >
                <v-list-item-title
                  ><v-icon left>mdi-calendar-plus</v-icon> Book
                  Appointment</v-list-item-title
                >
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                :to="{
                  name: 'PayOnline',
                  params: { cid: customer.id },
                }"
              >
                <v-list-item-title
                  ><v-icon left>mdi-cash-register</v-icon> Renew Subscription</v-list-item-title
                >
              </v-list-item>
              <v-divider></v-divider>
              <!-- TODO <v-list-item @click="alert('me')">
                <v-list-item-title><v-icon left>mdi-ticket</v-icon>
                  View Passes</v-list-item-title>
              </v-list-item> -->
              <v-list-item @click="showUploadPicture = true">
                <v-list-item-title
                  ><v-icon left>mdi-image</v-icon> Update
                  Photo</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>

        <v-card-subtitle>
          {{ customer.cust_no }}
          <span v-if="customer.relationship"
            >( {{ customer.relationship }} )</span
          >
        </v-card-subtitle>

        <v-card-subtitle class="mb-0" style="margin-top:-20px;">
          <v-badge
            v-if="activePasses"
            :content="activePasses"
            color="success"
            overlap
            left
            class="float-right"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar color="pink"
                v-bind="attrs"
                v-on="on"
                  ><v-icon dark>mdi-ticket-confirmation</v-icon></v-avatar
                >
                <!-- <v-btn
                  fab
                  dark
                  small
                  color="pink"
                  class="white--text"
                  v-bind="attrs"
                  v-on="on"
                  @click="showTip = !showTip"
                  ><v-icon dark>mdi-ticket-confirmation</v-icon></v-btn
                > -->
              </template>
              <span>Active Passes</span>
            </v-tooltip>
          </v-badge>
        </v-card-subtitle>
      </div>
    </div>

    <v-card-actions>
      <v-btn text @click="showQRCode = true"
        ><v-icon>mdi-qrcode</v-icon> QR Code</v-btn
      >
      <v-spacer></v-spacer>

      <v-badge
        :content="expiredSubscriptions"
        :color="expiredSubscriptions ? 'error' : ''"
        overlap
        left
      >
        <v-btn text @click="expandSub = !expandSub">
          Subscriptions
          <!-- <v-avatar size="22" class="red darken-4 ml-2">
          {{ expiredSubscriptions }}
        </v-avatar> -->
          <!-- <v-badge :content="1" color="red"> </v-badge> -->
          <v-icon v-if="expandSub" right>mdi-chevron-up</v-icon>
          <v-icon v-else right>mdi-chevron-down</v-icon>
        </v-btn>
      </v-badge>
    </v-card-actions>

    <v-divider></v-divider>
    <v-expand-transition>
      <v-list dense light v-show="expandSub">
        <template v-for="(service, service_index) in services">
          <v-list-item :key="service.service_customer_id">
            <v-list-item-content>
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <router-link
                    :to="{
                      name: 'MySubscription',
                      params: {
                        cid: customer.id,
                        scid: service.service_customer_id,
                      },
                    }"
                    exact
                  >
                    {{ service.service_name }}
                  </router-link>
                  <div v-if="service.passes_counter != null" class="pt-2">
                    <v-chip color="green" text-color="white"
                      ><v-icon left small>mdi-ticket-confirmation</v-icon>
                      Passes
                      <v-avatar
                        right
                        class="darken-4"
                        :class="
                          service.passes_counter == 0
                            ? 'red'
                            : service.passes_counter < 3
                            ? 'yellow'
                            : 'green'
                        "
                      >
                        {{ service.passes_counter }}
                      </v-avatar>
                    </v-chip>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column align-end">
                  <span class="text-caption text-no-wrap">{{
                    toDate(service.service_dateto)
                  }}</span>
                  <div>
                    <v-chip
                      v-if="!service.service_dateto"
                      small
                      color="orange"
                      text-color="white"
                      >No Record</v-chip
                    >
                    <v-chip
                      v-else-if="isExpired(service.service_dateto)"
                      small
                      color="red"
                      text-color="white"
                      >Expired</v-chip
                    >
                  </div>
                  <v-btn v-if="isExpired(service.service_dateto)" small color="primary" class="mt-1"
                    :to="{
                    name: 'PayOnline',
                    params: { cid: customer.id }
                  }"
                  >Renew Now</v-btn>
                  <!-- TODO <div class="mt-2">
                    <v-btn x-small outlined color="primary">Renew Now</v-btn>
                  </div> -->
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="service_index"></v-divider>
        </template>
      </v-list>
    </v-expand-transition>
    <!-- <v-expand-transition>
            <v-list dense light>
              <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Subscriptions</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(service, service_index) in customer.services">
                <v-list-item :key="service.service_customer_id">
                  <v-list-item-content
                    v-text="service.service_name"
                  ></v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                    v-text="service.service_dateto"
                  ></v-list-item-content>
                </v-list-item>
                <v-divider :key="service_index"></v-divider>
              </template>
              </v-list-group>
            </v-list>
          </v-expand-transition> -->
    <qrcode
      v-if="showQRCode"
      ref="qrcode"
      :value="'https://fee.plus/b/' + businessId + '/c/' + customer.id + '/qr'"
      @close="showQRCode = false"
    />
    <upload-picture
      v-if="showUploadPicture"
      ref="uploadPicture"
      :remove-photo="customer.picture_link ? true : false"
      @save="savePicture"
      @delete="deletePicture"
      @close="showUploadPicture = false"
      dialogTitle="Update Picture"
    />
  </v-card>
</template>
<script>
// TODO: subscriptions list
// TODO: all attendance list
import dateTimeMixin from "@/mixins/dateTimeMixin";

// var loadComponent = async function(im){
//   store.state.loading = true;
//   console.log(store.state.loading);
//   let c = await im();
//   store.state.loading = false;
//   console.log(store.state.loading);
//   return c;
// };

import store from "@/store";

export default {
  data() {
    return {
      showQRCode: false,
      showUploadPicture: false,
      // showTip: false,
      expandSub: false,
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    businessId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    savePicture(imageData) {
      this.$store
        .dispatch({
          type: "setCustomerPicture",
          dcid: this.isDependant ? this.customer.id : null,
          imageData: imageData,
        })
        .then((res) => {
          this.$refs.uploadPicture.close(res);
        });
    },
    deletePicture() {
      this.$store
        .dispatch({
          type: "deleteCustomerPicture",
          dcid: this.isDependant ? this.customer.id : null,
        })
        .then((res) => {
          this.$refs.uploadPicture.close(res);
        });
    },
  },
  computed: {
    services() {
      return this.$store.state.customer_services[this.customer.id]; // customer id
    },
    isDependant() {
      return this.$store.getters.isDependant(this.customer.id);
    },
    expiredSubscriptions() {
      return this.$store.getters.expiredSubscriptions(this.customer.id);
    },
    activePasses() {
      return this.$store.getters.activePasses(this.customer.id);
    },
  },
  watch: {
    expandSub(newVal) {
      if (newVal && (!this.services || !this.services.length)) {
        // load services
        this.$store.dispatch("fetchCustomerServices");
      }
    },
  },
  components: {
    //qrcode: loadComponent(() => import(/* webpackChunkName: "qrcode" */ "./QRCode"))
    qrcode: async () => {
      store.state.loading = true;
      let c = await import(/* webpackChunkName: "qrcode" */ "./QRCode");
      store.state.loading = false;
      return c;
    },
    uploadPicture: () =>
      import(/* webpackChunkName: "uploadPicture" */ "./UploadPicture"),
  },
  mixins: [dateTimeMixin],
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
